<template>
  <span
    :class="{ isBusy: busy }"
    @click="toggle"
  >
    <v-icon v-if="!followState">
      mdi-eye-off-outline
    </v-icon>
    <v-icon v-else>
      mdi-eye-check
    </v-icon>

  </span>
</template>

<script>
export default {
    name: 'Follow',
    components: {},
    props: [ 'orgsite','initState' ],
    data() {
        return {
            followState: false,
            busy: false
        };
    },
    watch: {
        initState: function( val ){
            this.followState = val;
        }
    },
    mounted() {


    },
    methods: {
        async toggle(){
            if( this.busy )return;
            this.busy = true;
            let res = await this.$http.followSite( this.orgsite,this.followState );
            console.log( res );
            if( res.ok ){
                this.followState = !this.followState ;
            }
            this.busy = false;
        }

    }
};
</script>

<style scoped>

  span {
    cursor: pointer;
  }

  .isBusy i.v-icon{
    color: #777 !important;
  }
</style>