<template>
  <v-card
    :href="'#/org/' + id"
    :loading="loading"
    class=" text-left"
    width="200"
    height="200"
    color="#fff"
  >
    <div
      class="topbar"
      :style="{ backgroundColor: store.background, color: store.fontColor }"
    />
    <v-img
      contain
      style="margin: auto"
      width="180"
      height="50"
      :src="store.logo"
    />
    <span class="km">{{ dist }}</span>
    <span class="follow">      
      <follow
        :orgsite="id"
        :init-state="true"
      /></span>
    <v-card-subtitle class="text-limit text-subtitle-2">
      {{ store.name }}
    </v-card-subtitle>
    <v-card-text class="text-limit">
      <a
        :href="gmaps()"
        target="_blank"
        style="text-decoration: none;"
      >
        <span>{{ store.address }}</span>
      </a>
    </v-card-text>
  </v-card>
</template>

<script>
import follow from "../components/Follow.vue";

export default {
    name: 'StoreCard',
    components: {
        follow
    },
    props: {
        id: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            store: {},
            loading: false,
            dist: null,
        };},
    computed: {

    },
    watch: {
        // geo: function( val ){
        //     this.dist = this.$http.getKm( this.post.geo, val );
        // }
    },
    async mounted() {
        
        this.$http.getSitePubCb( this.id, data => this.store = data );

    },
    methods: {
        gmaps(){
            return "https://www.google.com/maps/search/?api=1&query=" + encodeURIComponent( this.store.name );
        }
    }
};
</script>

<style scoped>

  a span:hover {
    color: var(--tccIcon);
    text-decoration: underline;
  }
  a span {
    color: #777;
  }
  .text-limit {
    height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
  }

  .km {
    display: none;
    position: absolute;
    top: 220px;
    right: 0;
    background-color: #3898ec;
    color: #fff;
    padding: 0 2px;
    border-radius: 8px;
  }
  .topbar {
    height: 20px;
    margin-bottom: 8px;
  }
  .follow {
    position: absolute;
    bottom: 4px;
    right: 4px;
  }
</style>