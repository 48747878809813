<template>
  <div
    class="mx-auto  pt-4"
    style="max-width: 800px"
  >
    <v-card
      class="elevation-2"
      :loading="loading"
    >
      <v-toolbar
        color="tccPrimary"
      >
        <v-toolbar-title> Manage your profile</v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-container>
        <v-card-title>{{ customer.email }}</v-card-title>    
        <v-card-subtitle>Joined the Club: {{ customer.joined | niceDate }}</v-card-subtitle>


        <v-row>
          <v-col
            cols="12"
            sm="9"
          >
            <v-text-field
              v-model="customer.first"
              label="First Name (Helps emails reach your inbox)"
              name="first"
              prepend-icon="mdi-account"
              type="text"
              maxlength="32"
            />
          </v-col>
          <v-col
            cols="6"
            sm="3"
          >
            <v-text-field
              v-model="customer.pcode"
              label="Postcode"
              name="postcode"
              prepend-icon="mdi-map-marker"
              type="text"
              maxlength="4"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-card-title>Email Alerts</v-card-title>
            <v-card-subtitle>
              <v-switch
                v-model="alert.on"
                flat
                label="Send me new clearance emails from stores in my area"
              />
            </v-card-subtitle>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-card-title>Keyword Filter</v-card-title>
            <v-card-subtitle>Only send me clearances that contain any of the following keywords:</v-card-subtitle>
            <div style="height: 30px;">
              <div
                v-show="errMsg"
                class="msgBox"
              >
                {{ errMsg }}
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="6">
            <v-text-field
              v-model="newKeyword"
              label="Keyword"
              name="keyword"
              type="text"
              maxlength="50"
              @focus="errMsg=''"
            />
          </v-col>
          <v-col
            cols="3"
          >
            <v-text-field
              v-model="maxPrice"
              label="Max Price (optional)"
              name="max"
              type="text"
              maxlength="10"
            />
          </v-col>
          <v-col
            class="d-flex justify-center align-center"
            cols="3"
          >
            <v-btn
              :disabled="newKeyword.length < 2"
              color="neutral"
              min-width="100px"
              @click="addKeyword()"
            >
              ADD
            </v-btn>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="9">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Keyword
                    </th>
                    <th class="text-left">
                      Max Price
                    </th>
                    <th width="10%" />
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(kw, index) in alert.kw"
                    :key="index + kw.k"
                  >
                    <td width="60%">
                      {{ kw.k }}
                    </td>
                    <td>{{ kw.m }}</td>
                    <td
                      class="td-del"
                    >
                      <v-icon
                        color="negative"
                        @click="delKeyword(index)"
                      >
                        mdi-trash-can
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            min-width="100px"
            dark
            @click="updateCustomer()"
          >
            SAVE
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>

    <v-card
      v-if="false"
      class="elevation-5 mt-8"
      :loading="loading"
    >
      <v-toolbar
        color="tccBlue"
        flat
        dark
      >
        <v-toolbar-title>Stores you are following</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-card-text>
        <v-row
          dense
          no-gutters
        >
          <v-col cols="7">
            <v-text-field
              label="Search"
              name="search"
              prepend-icon="mdi-magnify"
              type="text"
            />
          </v-col>
          <v-col cols="3" />
        </v-row>

        <v-row>
          <v-col
            v-for="(item, index) in customer.follows"
            :key="index"
            cols="auto"
          >
            <store-card :id="item" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>


<script>
import storeCard from "../components/StoreCard.vue";

export default {
    name: "Manage",
    components: {
        storeCard,
    },
    data() {
        return {
            loading: false,
            customer: {},
            newKeyword: "",
            maxPrice: "",
            errMsg: "",
            follows: [],
            alert: {}
        };
    },
    computed: {},
    async mounted() {
        console.log( "MANAGE MOUNTED" );
        document.title = "The Clearance Club - Manage your profile";

        this.loading = true;
        let res = await this.$http.getCustomer();
        console.log( res );
        this.loading = false;
        // console.log( res );
        if ( res.ok ) {
            this.customer = res.data;
            this.alert = res.data.alert || {};
            this.$emit( "user", res.data, res.token );
            // this.follows = res.data.follows.map( v => v.pub );
        }

        // this.$router.replace('/manage'); //clean up url bar
    },
    methods: {
        addKeyword(){
            console.log( this.newKeyword, this.maxPrice );

            if( this.newKeyword.length < 2 )return this.errMsg = "Keyword is too short";

            if( !this.alert.kw )this.alert.kw = [];
            this.alert.kw.push( {
                k: this.newKeyword,
                m: this.maxPrice
            } );
            this.newKeyword = '';
            this.maxPrice = '';
        },
        delKeyword( i ){
            this.alert.kw.splice( i,1 );
        },
        async updateCustomer() {
            let resp = await this.$http.updateCustomer( this.customer );
            if ( resp.ok ) this.$emit( "snack", "Profile updated" );
            else this.$emit( "Update failed!", "error" );
        },
    },
};
</script>

<style scoped>
  .td-del button {
    width: 10%;
    visibility: hidden;
  }
  tr:hover .td-del button {
    visibility: visible;
    cursor: pointer;
  }
  .msgBox {
    padding: 4px;
    position: absolute;
    left: 10%;
    width: 80%;
    height: 30px;
    margin: auto;
    text-align: center;
    color: #fff;
    background-color: var(--v-negative-base);
    border-radius: 4px;
  }
</style>